
.app {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.top-nav {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: #333;
  color: white;
  padding: 10px 20px;
  position: relative;
  z-index: 2; /* Ensure top navigation is above sidebar */
}

.hamburger {
  font-size: 1.5rem;
  cursor: pointer;
  display: none;
}

.container_video {
  display: flex;
  flex: 1;
  overflow: hidden;
  margin-top: 20px;
}

.sidebar {
  background-color: #fff;
  color: black;
  width: 30%;
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  transition: transform 0.3s ease;
  transform: translateX(0); /* Ensure sidebar is initially visible */
  height: calc(100vh - 60px); /* Adjust height of sidebar */
  position: relative;
}

.sidebar.open {
  transform: translateX(-100%);
}

.sidebar-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.sidebar-hamburger {
  font-size: 1.5rem;
  cursor: pointer;
}

.sidebar-title {
  font-size: 1.2rem;
  font-weight: bold;
}

.panel-menu-container {
  overflow-y: auto;
  height: calc(100% - 40px); /* Adjust height of PanelMenu */
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: #fff;
  padding: 20px;
  overflow-y: auto;
  transition: filter 0.3s ease; /* Smooth transition for blur effect */
}

.video-section {
  width: 100%;
  max-width: 800px; /* Made the video card slightly larger */
  background: white;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-bottom: 20px;
}

.video-container {
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
  position: relative;
}

video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

@media (max-width: 768px) {
  .hamburger {
      display: block;
  }
  .sidebar {
      width: 100%;
      height: 100%;
      position: fixed;
      top: 0;
      left: 0;
      transform: translateX(-100%);
  }
  .sidebar.open {
      transform: translateX(0);
  }
  .sidebar.open .sidebar-hamburger {
      display: block;
  }
  .content.content-blur {
      filter: blur(2px);
  }
}
.app {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.top-nav {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: #333;
    color: white;
    padding: 10px 20px;
    position: relative;
    z-index: 2; /* Ensure top navigation is above sidebar */
}

.hamburger {
    font-size: 1.5rem;
    cursor: pointer;
    display: none;
}

.container_video {
    display: flex;
    flex: 1;
    overflow: hidden;
    margin-top: 20px;
}

.sidebar {
    background-color: #fff;
    color: black;
    width: 30%;
    padding: 20px;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    transition: transform 0.3s ease;
    transform: translateX(0); /* Ensure sidebar is initially visible */
    height: calc(100vh - 60px); /* Adjust height of sidebar */
    position: relative;
}

.sidebar.open {
    transform: translateX(-100%);
}

.sidebar-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.sidebar-hamburger {
    font-size: 1.5rem;
    cursor: pointer;
}

.sidebar-title {
    font-size: 1.2rem;
    font-weight: bold;
}

.panel-menu-container {
    overflow-y: auto;
    height: calc(100% - 40px); /* Adjust height of PanelMenu */
}

.content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: #fff;
    padding: 20px;
    overflow-y: auto;
    transition: filter 0.3s ease; /* Smooth transition for blur effect */
}

.video-section {
    width: 100%;
    max-width: 800px; /* Made the video card slightly larger */
    background: white;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin-bottom: 20px;
}

.video-container {
    width: 100%;
    padding-top: 56.25%; /* 16:9 Aspect Ratio */
    position: relative;
}

video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
}

@media (max-width: 768px) {
    .hamburger {
        display: block;
    }
    .sidebar {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        transform: translateX(-100%);
    }
    .sidebar.open {
        transform: translateX(0);
    }
    .sidebar.open .sidebar-hamburger {
        display: block;
    }
    .content.content-blur {
        filter: blur(2px);
    }
}
.app {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.top-nav {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: #333;
    color: white;
    padding: 10px 20px;
    position: relative;
    z-index: 2; /* Ensure top navigation is above sidebar */
}

.hamburger {
    font-size: 1.5rem;
    cursor: pointer;
    display: none;
}

.container_video {
    display: flex;
    flex: 1;
    overflow: hidden;
    margin-top: 20px;
}

.sidebar {
    background-color: #fff;
    color: black;
    width: 30%;
    padding: 20px;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    transition: transform 0.3s ease;
    transform: translateX(0); /* Ensure sidebar is initially visible */
    height: calc(100vh - 60px); /* Adjust height of sidebar */
    position: relative;
}

.sidebar.open {
    transform: translateX(-100%);
}

.sidebar-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.sidebar-hamburger {
    font-size: 1.5rem;
    cursor: pointer;
}

.sidebar-title {
    font-size: 1.2rem;
    font-weight: bold;
}

.panel-menu-container {
    overflow-y: auto;
    height: calc(100% - 40px); /* Adjust height of PanelMenu */
}

.content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: #fff;
    padding: 20px;
    overflow-y: auto;
    transition: filter 0.3s ease; /* Smooth transition for blur effect */
}

.video-section {
    width: 100%;
    max-width: 800px; /* Made the video card slightly larger */
    background: white;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin-bottom: 20px;
}

.video-container {
    width: 100%;
    padding-top: 56.25%; /* 16:9 Aspect Ratio */
    position: relative;
}

video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
}

@media (max-width: 768px) {
     
    .sidebar {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        transform: translateX(-100%);
    }
    .sidebar.open {
        transform: translateX(0);
    }
    .sidebar.open .sidebar-hamburger {
        display: block;
    }
    .content.content-blur {
        filter: blur(2px);
    }
}
.app {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.top-nav {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: #333;
    color: white;
    padding: 10px 20px;
    position: relative;
    z-index: 2; /* Ensure top navigation is above sidebar */
    display: none;
}

.hamburger {
    font-size: 1.5rem;
    cursor: pointer;
    display: none;
}

.container_video {
    display: flex;
    flex: 1;
    overflow: hidden;
    margin-top: 20px;
}

.sidebar {
    background-color: #fff;
    color: black;
    width: 30%;
    padding: 20px;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    transition: transform 0.3s ease;
    transform: translateX(0); /* Ensure sidebar is initially visible */
    height: calc(100vh - 60px); /* Adjust height of sidebar */
    position: relative;
}

.sidebar.open {
    transform: translateX(-100%);
}

.sidebar-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.sidebar-hamburger {
    font-size: 1.5rem;
    cursor: pointer;
}

.sidebar-title {
    font-size: 1.2rem;
    font-weight: bold;
}

.panel-menu-container {
    overflow-y: auto;
    height: calc(100% - 40px); /* Adjust height of PanelMenu */
}

.content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: #fff;
    padding: 20px;
    overflow-y: auto;
    transition: filter 0.3s ease; /* Smooth transition for blur effect */
}

.video-section {
    width: 100%;
    max-width: 800px; /* Made the video card slightly larger */
    background: white;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin-bottom: 20px;
}

.video-container {
    width: 100%;
    padding-top: 56.25%; /* 16:9 Aspect Ratio */
    position: relative;
}

video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
}

@media (max-width: 768px) {
    .hamburger {
        display: block;
    }
    .top-nav{
      display: block; 
    }
    .sidebar {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        transform: translateX(-100%);
    }
    .sidebar.open {
        transform: translateX(0);
    }
    .sidebar.open .sidebar-hamburger {
        display: block;
    }
    .content.content-blur {
        filter: blur(2px);
    }
}