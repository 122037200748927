/* General styles */
.footer-container {
    margin: 0;
    padding: 0;
    background-color: #f4f4f4; /* Ensure consistent background */
}

.footer {
    display: flex;
    flex-flow: row wrap;
    padding: 30px 30px 20px 30px;
    color: #2f2f2f;
    background-color: #fff;
    border-top: 1px solid #e5e5e5;
    width: 100%;
    box-sizing: border-box;
}

.footer > * {
    flex: 1 100%;
}

.footer__addr {
    margin-right: 1.25em;
    margin-bottom: 2em;
}

.footer__logo {
    font-family: 'Pacifico', cursive;
    font-weight: 400;
    text-transform: lowercase;
    font-size: 1.5rem;
}

.footer__addr h2 {
    margin-top: 1.3em;
    font-size: 15px;
    font-weight: 400;
}

.nav__title {
    font-weight: 400;
    font-size: 15px;
}

.footer address {
    font-style: normal;
    color: #999;
}

.footer__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    max-width: max-content;
    background-color: rgb(33, 33, 33, 0.07);
    border-radius: 100px;
    color: #2f2f2f;
    line-height: 0;
    margin: 0.6em 0;
    font-size: 1rem;
    padding: 0 1.3em;
}

.footer ul {
    list-style: none;
    padding-left: 0;
}

.footer li {
    line-height: 2em;
}

.footer a {
    text-decoration: none;
}

.footer__nav {
    display: flex;
    flex-flow: row wrap;
}

.footer__nav > * {
    flex: 1 50%;
    margin-right: 1.25em;
}

.nav__ul a {
    color: #999;
}

.nav__ul--extra {
    column-count: 2;
    column-gap: 1.25em;
}

.legal {
    display: flex;
    flex-wrap: wrap;
    color: #999;
    width: 100%;
}

.legal__links {
    display: flex;
    align-items: center;
}

.heart {
    color: #2f2f2f;
}

@media screen and (min-width: 24.375em) {
    .legal .legal__links {
        margin-left: auto;
    }
}

@media screen and (min-width: 40.375em) {
    .footer__nav > * {
        flex: 1;
    }

    .nav__item--extra {
        flex-grow: 2;
    }

    .footer__addr {
        flex: 1 0px;
    }

    .footer__nav {
        flex: 2 0px;
    }
}
