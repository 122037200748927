/* Container styles */
.container {
    display: flex;
    flex-direction: row;
    height: 100vh;
    position: relative;
  }
  .course-track{
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-size: large;
     text-align: left;
     color: black;
  }
  .course-title{
    font-family: 'Courier New', Courier, monospace;
    font-size: larger;
    text-align: left;
  }
  .video-title{
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-size: medium;
    text-align: left;
  }
  /* Hamburger icon styles */
  .hamburger {
    display: none;
    position: absolute;
    top: 10px;
    left: 10px;
    font-size: 24px;
    cursor: pointer;
    z-index: 1000;
  }
  
  /* Side menu styles */
  .side-menu {
    background-color: #f8f9fa;
    flex: 0 0 33.33%;
    overflow-y: auto;
    padding: 1rem;
    transition: transform 0.3s ease;
  }
  
  .side-menu.open {
    transform: translateX(0);
  }
  
  /* Video player styles */
  .video-player {
    background-color: #ffffff;
    flex: 1;
    padding: 1rem;
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .container {
      flex-direction: column;
    }
  
    .hamburger {
      display: block;
    }
  
    .side-menu {
      position: absolute;
      top: 0;
      left: 0;
      width: 70%;
      height: 100%;
      transform: translateX(-100%);
      z-index: 1000;
    }
  
    .side-menu.open {
      transform: translateX(0);
    }
  
    .video-player {
      flex: 1;
    }
  }
  