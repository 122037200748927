
.page-heading-card { 
    display: flex;
    background-color: #f6f4f4;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-bottom: 20px;
    margin-top: 20px;
    position: relative; /* Ensure relative positioning for absolute button */
    
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.page-heading-content {
    flex: 1; /* Take remaining space */
}

.page-heading-content h1 {
    margin-bottom: 10px;
    color: #333;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.page-heading-content ch {
    margin-bottom: 10px;
    color: #333;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 20px ;
}

.page-heading-content p {
    font-size: 1.1em;
    color: #666;
    margin: 8px 0;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}
.stats{
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-size: 1.3em;
    color: #290d0d;
}
.page-heading-content h5 {
    font-size: 1.5em;
    color: #666;
    margin: 8px 0;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.page-heading-content .module-count,
.page-heading-content .course-count {
    font-weight: bold;
    color: #007bff; /* Adjust color as needed */
}

.enroll-button {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100px; /* Adjust button width */

}

.enroll-button button {
    width: 100%;
    height: 100%;
    background-color: #007bff; /* Button background color */
    color: white;
    border: none;
    border-radius: 0 10px 10px 0; /* Rounded corner on the left side */
    cursor: pointer;
    font-size: 1em;
    transition: background-color 0.3s ease;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-weight: bold;
    

}

.enroll-button button:hover {
    background-color: #0056b3; /* Darker background color on hover */
}


.goto-button {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 300px; /* Adjust button width */

}

.goto-button button {
    width: 100%;
    height: 100%;
    background-color: #4a6077; /* Button background color */
    color: white;
    border: none;
    border-radius: 0 10px 10px 0; /* Rounded corner on the left side */
    cursor: pointer;
    font-size: 1em;
    transition: background-color 0.3s ease;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-weight: bold;
    

}

.goto-button button:hover {
    background-color: #98a4b1; /* Darker background color on hover */
}


/* Media query for smaller screens */
@media (max-width: 768px) {
    .page-heading-card {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
    
    .enroll-button {
        position: relative;
        margin-top: 20px;
        width: 100%;
        font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    }

    .enroll-button button {
        border-radius: 10px;
     }

     
    .goto-button {
        position: relative;
        margin-top: 20px;
        width: 100%;
        font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    }

    .goto-button button {
        border-radius: 10px;
     }
}

