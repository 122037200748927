.course-offerings-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: 20px;
  }
  
  .course-card {
    background-color: #f5f5f5;
    padding: 20px;
    margin: 10px;
    border-radius: 8px;
    width: 250px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .learn-more-btn {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .learn-more-btn:hover {
    background-color: #0056b3;
  }
  