/* Overall background of the page */
.checkout-page {
    background-color: #fee4c3;
    padding: 50px 0; /* Adding space between the navbar and checkout component */
    min-height: 100vh; /* Ensures the page covers the full viewport */
  }
  
  /* Checkout container background and adjustments */
  .checkout-container {
    width: 60%; /* Increased width by 10% */
    margin: auto;
    padding: 30px;
    background-color: white; /* Keeping checkout background white */
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  /* Heading styling */
  h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  /* Responsive layout for course details */
  .course-details {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; /* Allows wrapping on smaller screens */
    margin-bottom: 20px;
  }
  
  /* Course info section */
  .course-info {
    flex: 2;
  }
  
  .course-info strong {
    display: block;
    margin-bottom: 10px;
  }
  
  .course-title {
    font-size: 1.1em;
    font-weight: bold;
  }
  
  .fee-inclusive {
    color: grey;
    font-size: 0.9em;
  }
  
  .course-access {
    color: #777;
    font-size: 0.8em;
    margin-top: 5px;
  }
  
  /* Course amount section */
  .course-amount {
    flex: 1;
    text-align: right;
  }
  
  .course-amount strong {
    display: block;
    margin-bottom: 10px;
  }
  
  .course-amount p {
    font-size: 1.2em;
  }
  
  /* Total fee styling */
  .total-fee {
    text-align: right;
    font-size: 1.2em;
    margin-bottom: 20px;
  }
  
  /* Payment button styles */
  .proceed-button {
    background-color: red;
    color: white;
    border: none;
    padding: 10px 20px;
    text-transform: uppercase;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
    display: block;
    margin: 20px auto 0;
  }
  
  .proceed-button:hover {
    background-color: #d00000;
  }
  
  /* Footer padding and adjustment */
  footer {
    margin-top: 50px; /* Adds space between checkout component and footer */
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .checkout-container {
      width: 90%; /* Adjusts container width for smaller screens */
    }
  
    .course-amount {
      text-align: left;
      margin-top: 20px;
    }
  
    .total-fee {
      font-size: 1em;
    }
  }
  