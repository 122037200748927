.card_login {
  max-width: 60%; /* Adjusted max-width for larger screens */
  background: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  margin: 5% auto; /* Center the card horizontally with 5% margin from top */
  font-family: Arial, sans-serif;
  display: flex;
}
.simple-login {
  text-align: center;
  padding: 20px;
}
.linkedin-login button {
  width: 200px; /* Control button width */
  height: 35px; /* Smaller height */
  font-size: 14px;
  display: block;
  margin: 10px auto;
}

.simple-login h3 {
  margin-bottom: 20px;
  font-family: Arial, sans-serif;
}

.column-auth{
  padding: 20px;
}

.column-left {
  width: 65%;
  background-color: #f4f4f4; /* Light grey background for demonstration */
}

.column-right {
  width: 35%;
  background-color: #ddd; /* Darker grey background for demonstration */
}

/* Optional: Responsive design */
@media (max-width: 768px) {
  .card-login {
      flex-direction: column;
      max-width: 100%; /* Adjusted max-width to cover entire width of device */
  }
  .column-right {
      width: 100%;
  }
  .column-left {
    width: 0%;
}
}
 

@media (max-width: 768px) {
  .card_login {
    max-width: 100%; /* Adjusted max-width to cover entire width of device */
  }
   
}
