.learn-panel {
    width: 75%;
    margin: 0 auto;
    position: relative;
    display: flex;
    align-items: center;
    background-color: bisque;
    justify-content: center;
    
}
.row1 {
    display: flex;
 }

.column1 {
    padding: 10px;
    
}
.learn-card {
    flex: 0 0 22%; /* Ensure four cards fit within the panel */
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    margin: 0 2rem;
    background: #efefef;
    border-radius: 8px;
    margin-top: 50px;
}
 
 
.text-column1 {
    width: 100%;
   
}

.heading1 {
    margin-top: 20px;
    font-family: cursive;
    font-size: 1.5em;
    color: black;
    margin-bottom: 10px;

}

.heading1 .scratch{
    font-weight: bold;
    color: brown;
}

.para { 
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-size: 1.2em;
}

.heading1 .highlight{
    font-weight: bold;
     
}

@media (max-width: 768px) {
    .row1 {
        flex-direction: column;
        align-items: center; /* Align items (including the image) in the center */
        justify-content: center; /* Justify content (text) in the center */
    }
 
    .text-column1 {
        width: 100%;
        text-align: left; /* Justify the text content */
    }
}