 
html, body {
  height: 100%; /* Ensure the body and html take full viewport height */
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Make the root container cover the full viewport */
}

.footer-container {
  margin: 0;
  padding: 0;
  flex-shrink: 0; /* Ensure the footer doesn't grow unexpectedly */
}

.content {
  flex-grow: 1; /* Push the footer to the bottom */
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
