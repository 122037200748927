.trackinfo-page {
    font-family: 'Arial', sans-serif;
    background-color: #fee4c3;
    padding: 40px 20px;
}

/* Header Section */
.trackinfo-header {
    font-size: 2.5em;
    font-weight: bold;
    color: #2c3e50;
    font-family: 'Cambria', serif;
    margin-bottom: 20px;
    text-align: center;
}

.trackinfo-subheader {
    font-size: 1.2em;
    color: #333;
    margin-bottom: 20px;
    text-align: center;
}
 
/* Button Section */
.trackinfo-button-wrapper {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 30px;
}
 
  .outcome{
    font-size: 1.5 em;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    margin-bottom: 20px;
  }
.trackinfo-started-button{
    background-color: #4aa7c1;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1em;
    transition: background-color 0.3s ease;
    text-decoration: none;
    text-align: center;
    min-width: 150px; /* Ensure buttons are aligned properly */
    width: 100%;
}
.trackinfo-enroll-button,
.trackinfo-reviews-button,
.trackinfo-outcome-button{
    background-color: #e67e22;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1em;
    transition: background-color 0.3s ease;
    text-decoration: none;
    text-align: center;
    min-width: 150px; /* Ensure buttons are aligned properly */
}


.trackinfo-enroll-button:hover {
    background-color: #d35400;
}

.trackinfo-reviews-button {
    background-color: #2980b9; /* Different color for the Reviews button */
}
.trackinfo-outcome-button {
    background-color: #2b9e74; /* Different color for the Reviews button */
}


.trackinfo-reviews-button:hover {
    background-color: #1f6b9a;
}
.trackinfo-outcome-button:hover {
    background-color: #839eaf;
}
/* Course Section */
.trackinfo-section {
    display: flex;
    align-items: center;
     
    padding: 20px;
    justify-content: space-between;
    flex-direction: row;
    margin-bottom: 80px;
}

.trackinfo-section:nth-child(even) {
    flex-direction: row-reverse; /* Zig-zag for even sections on larger screens */
}

.trackinfo-image {
    flex: 1;
    padding: 10px;
    max-width: 50%;
}

.trackinfo-image img {
    width: 100%;
    border-radius: 10px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

.trackinfo-content {
    flex: 1;
    padding: 30px;
    text-align: left;
}

.trackinfo-title {
    font-size: 1.5em;
    font-weight: bold;
    margin-bottom: 15px;
}

.trackinfo-description {
    font-size: 1.2em;
    color: #555;
    line-height: 1.6;
    margin-bottom: 20px;
}

.trackinfo-highlights {
    font-size: 1em;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
}

/* Review Section Styling */
.trackinfo-reviews {
    text-align: center;
    background-color: #fee4c3;
    padding: 40px 20px;
    margin: 60px 0;
    position: relative;
  }
  
  .trackinfo-review-title {
    font-size: 2em;
    margin-bottom: 20px;
    color: #333;
  }
  
  .trackinfo-review-card {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    padding: 30px; /* Increase padding */
    min-width: 320px; /* Adjust the minimum width */
    max-width: 350px; /* Adjust the maximum width */
    box-sizing: border-box;
    margin: 0 auto; /* Center the card */
  }
  
  .trackinfo-review-text {
    font-size: 1.1em; /* Increase font size for readability */
    margin-bottom: 15px;
    color: #555;
  }
  
  .trackinfo-review-company,
  .trackinfo-review-name {
    font-size: 1.1em; /* Increase font size */
  }
  
  .trackinfo-review-name {
    font-weight: bold;
    color: #333;
    margin-top: 10px;
  }

/* Arrow styles */
.trackinfo-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: white;
    border: none;
    padding: 10px;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
    z-index: 10;
}

.trackinfo-arrow-left {
    left: 10px;
}

.trackinfo-arrow-right {
    right: 10px;
}

.trackinfo-arrow svg {
    width: 24px;
    height: 24px;
}

.key-highlights ul {
    list-style-type: none; /* Remove default bullet points */
    padding: 0;
    line-height: 1.8;
}

.key-highlights li {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.key-highlights li span {
    margin-right: 10px;
    color: #ff6b6b;
    font-size: 18px;
}

/* General button styling */
.button-group {
    display: flex;
    flex-direction: row; /* Display buttons in a row for larger screens */
    gap: 10px; /* Add space between buttons */
     
    justify-content: center; /* Center the buttons horizontally */
  }
  
  .button-group button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    color: white;
    font-size: 1em;
    transition: background-color 0.3s ease;
    margin-top: 20px; /* Add space above the button */
  }
  
  /* Button colors for different actions */
.trackinfo-enroll-button {
    background-color: #e67e22;
  }
  
  .trackinfo-outcome-button {

    background-color: #2b9e74;
  }
  
  .trackinfo-reviews-button {
    background-color: #2980b9;
  }
  
  .trackinfo-whatsapp-button {
    background-color: #25D366;
  }
  
  .trackinfo-email-button {
    background-color: #63bdd6;
  }
  
  .trackinfo-fee-button {
    background-color: #b4336f;
  }
  
  
/* Hover effects */
.trackinfo-enroll-button:hover {
    background-color: #d35400;
  }
  
  .trackinfo-outcome-button:hover {
    background-color: #1e7856;
  }
  
  .trackinfo-reviews-button:hover {
    background-color: #1f6b9a;
  }
  
  .trackinfo-whatsapp-button:hover {
    background-color: #1eb853;
  }
  
  .trackinfo-email-button:hover {
    background-color: #4da7c4;
  }
  
  .trackinfo-fee-button:hover {
    background-color: #99285a;
  }
  
  
/* Mobile Responsiveness */
@media (max-width: 768px) {
    .trackinfo-section {
        flex-direction: column; /* Stack content vertically */
        text-align: center;
        margin-bottom: 60px;
      }
    
      .trackinfo-section:nth-child(even) {
        flex-direction: column; /* Remove zig-zag for even sections on mobile */
      }
    
      .trackinfo-image {
        order: 1;
        max-width: 100%;
        margin-bottom: 20px;
      }
    
      .trackinfo-content {
        order: 2;
        padding: 20px;
      }
    
      .key-highlights {
        order: 3;
        text-align: left;
      }
    
      /* Adjust buttons for mobile */
      .button-group {
        flex-direction: column; /* Stack buttons vertically on smaller screens */
        align-items: center; /* Center align the buttons */
      }
    
      .button-group button {
        width: 100%; /* Make buttons take full width */
        margin-bottom: 10px; /* Add spacing between buttons */
      }
    
      /* Review Section */
      .trackinfo-review-card {
        flex: 0 0 100%; /* Show one card at a time */
        padding: 20px;
    min-width: 100%; /* Ensure the card takes full width on mobile */
      }
    
      /* Make sure the slider shows only one card */
      .trackinfo-review-scroller-inner {
        flex-direction: row;
        justify-content: center;
        width: 100%;
      }
    
      .trackinfo-review-title {
        font-size: 1.5em; /* Adjust title size for mobile */
        margin-bottom: 15px;
      }
}
