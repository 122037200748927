/* Reset the text and link styles */
a:hover {
    text-decoration: none;
}

/* Restore the background for the entire MegaMenu dropdown panel */
.custom-megamenu .p-megamenu-panel {
    background-color: #fff !important; /* White background for the panel */
    min-width: 900px !important; /* Ensure enough width for long text */
    border-radius: 4px; /* Optional: border-radius */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    padding: 10px 20px; /* Add padding for space */
}
.p-megamenu {
    display: flex !important; /* Fix display issue for PrimeReact MegaMenu */
  }
/* Make sure the text is fully visible and stays on a single line */
.custom-megamenu .p-menuitem-link {
    display: inline-block; /* Keep inline for hover effects */
    width: auto; /* Allow for auto width to fit the text */
    padding: 0.5em 1em; /* Add padding around the text */
    background-color: transparent !important; /* No background by default */
    white-space: nowrap; /* Prevent text wrapping */
    overflow: visible; /* Ensure text is fully visible */
    text-overflow: clip; /* Remove ellipsis */
    box-sizing: border-box; /* Include padding in width calculation */
}

/* Apply the custom hover effect with full width */
.custom-megamenu .p-menuitem-link:hover {
    background-color: #edeffb !important; /* Apply the hover background */
    border-radius: 4px; /* Optional: round the corners */
    display: inline-block; /* Ensure hover behavior is consistent */
    width: auto; /* Ensure the background covers just the text */
    transition: background-color 0.2s ease-in-out; /* Smooth hover transition */
}

/* Remove any conflicting background or margin from parent items */
.custom-megamenu .p-menuitem {
    background-color: transparent !important; /* Remove any parent background */
    padding: 0 !important;
    margin: 0 !important;
}

/* Ensure no conflicting backgrounds on submenu items */
.custom-megamenu .p-menuitem-link::before {
    background-color: transparent !important; /* Remove any default background */
}
