.course-detail-container {
    width: 80%; /* Reduce the width for better alignment */
    margin: 20px auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .course-section {
    margin-top: 20px;
  }
  
  h1, h2 {
    text-align: left;
    color: #333;
  }
  
  .course-fee, .course-duration, .course-prerequisites, .learning-outcomes, .instructor-info {
    margin-bottom: 20px;
  }
  
  .course-fee ul, .learning-outcomes ul {
    list-style-type: none;
    padding: 0;
  }
  
  .course-fee ul li, .learning-outcomes ul li {
    margin-bottom: 5px;
  }
  
  .course-enroll-now {
    margin-top: 40px;
    text-align: center;
  }
  
  .course-enroll-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 12px 24px;
    font-size: 1.2rem;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .course-enroll-button:hover {
    background-color: #0056b3;
  }
  
  .course-enroll-fixed {
    position: fixed;
    right: 20px;
    bottom: 40px;
    background-color: #007bff;
    color: white;
    padding: 12px 20px;
    font-size: 1.2rem;
    border-radius: 8px;
    cursor: pointer;
    z-index: 100;
  }
  